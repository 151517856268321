import React from 'react';
import JSONDataDisplay from './JSONDataDisplay';


function ClassTable() {
  return (
    <div>
      
      <JSONDataDisplay />
    </div>
  );
}

export default ClassTable;
