import Footer from "../components/Footer";
import ClassTable from "./components/ClassTable";


function App() {
  return (
    <>
    <ClassTable />
    <Footer /></>
  );
}

export default App;
