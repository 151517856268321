import React, {useState} from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer.jsx";
import Card from "react-bootstrap/Card";
import "./About.css"; // Import the CSS file for styling
import { FaHeart } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import "./footer.css";
import { useUserAuth } from "../context/UserAuthContext";
import {Helmet} from 'react-helmet';

function FAQDropdown({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button className="btn btn-link" onClick={handleToggle}>
        {isOpen ? '-' : '+'}
      </button>
      <span>{question}</span>
      {isOpen && <p>{answer}</p>}
    </div>
  );
}

const About = () => {
  const donateLink =
    "https://www.paypal.com/donate/?business=QJVMT3XYM37NA&no_recurring=0&item_name=Your+donation+will+be+put+towards+the+hosting+and+operating+costs+of+enRollBadge.&currency_code=USD";
  const feedbackLink = "https://forms.gle/65xy8qoiGbTDiooW8";
  const { user } = useUserAuth();

  const faqData = [
    {
      question: 'How does the notification system work for open and waitlist seats?',
      answer: 'The notification system will tell you that the class is "open" when it has waitlist seats available, so you are notified for both. In the coming months, we assure you we are actively working to distinguish open and waitlist slots.',
    },
    {
      question: 'I haven\'t received an email notification, what should I do?',
      answer: 'If you haven\'t received an email, it is possible that it has landed in your spam folder. Make sure to mark it as "Not Spam" to ensure you receive future notifications directly to your inbox.',
    },
    // Add more FAQ data as needed
  ];

  const renderFAQs = () => {
    return faqData.map((faq, index) => (
      <FAQDropdown key={index} question={faq.question} answer={faq.answer} />
    ));
  };

  return (
    <div className="about-container">
      <div className="about-content">
        
      <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-BXQHT68R6H"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-BXQHT68R6H');
            `}
          </script>
        </Helmet>

        <h1 className="text-center my-4">About</h1>
        <h5 className="text-center" style={{ color: "gray" }}>
          Simplifying Course Enrollment at UW Madison.
        </h5>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card
            className="about-card"
            style={{ width: "550px", marginTop: "20px" }}
          >
            <Card.Body>
              <Card.Text style={{ textAlign: "center", fontSize: 14 }}>
              enRollBadge is a web app designed for UW Madison students to streamline the class enrollment process. Say goodbye to constant manual checks and missed opportunities. With enRollBadge, you'll receive real-time notifications when your desired classes become open or waitlisted. Simply <Link to="/signup">sign in</Link>, select your classes, and let enRollBadge handle the rest. Experience stress-free enrollment and focus on what matters most – your education. Join enRollBadge today and say hello to a smarter way of enrolling.
                <br />
                <br />
                {user ? (
                  <h5 id="getstarted">Get Started With EnRollBadge Today!</h5>
                ) : (
                  <Link to="/signup">
                    <h5 id="getstarted">Get Started With EnRollBadge Today!</h5>
                  </Link>
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        </div>


        <h1 className="text-center my-4">FAQ</h1>

<div style={{ display: 'flex', justifyContent: 'center' }}>
  <Card className="about-card" style={{ width: '550px', marginTop: '10px' }}>
    <Card.Body>
      <Card.Text style={{ textAlign: 'center', fontSize: 14 }}>
        {renderFAQs()}
      </Card.Text>
    </Card.Body>
  </Card>
</div>


        <h1 className="text-center my-4">Help</h1>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card
            className="about-card"
            style={{ width: "550px", marginTop: "10px" }}
          >
            <Card.Body>
              <Card.Text style={{ textAlign: "center", fontSize: 14 }}>
                <p>
                  If you have issues, questions or feedback regarding this
                  project, please click on the 'Feedback' link. We will get back
                  to you as soon as we can. There is always scope to improve
                  this website and add features you want us to so we would love
                  to hear from you.
                </p>
                Hosting this website , using proxies to track status and running
                a server costs money. Help us keep this project going by
                clicking the 'Donate' button below.
              </Card.Text>
              <div
                className="footer-buttons my-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px 0",
                }}
              >
                <a
                  className="feedback-button green"
                  href={feedbackLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ margin: "0 10px", textDecoration: "none" }}
                >
                  <span className="feedback-icon">
                    <BsPencilFill />
                  </span>
                  Feedback
                </a>
                <a
                  className="donate-button orange"
                  href={donateLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ margin: "0 10px" }}
                >
                  <span className="donate-icon">
                    <FaHeart />
                  </span>
                  Donate
                </a>
              </div>
            </Card.Body>
          </Card>
        </div>


      </div>
      <Footer />
    </div>
  );
};

export default About;
