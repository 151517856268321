// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-container {
    padding: 20px;
  }
  
  .about-heading {
    color: #333;
  }
  
  .about-card {
    margin-bottom: 20px;
  }
  
  .highlighted-card {
    background-color: #f8f8f8;
    border: 2px solid #4285f4;
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .about-card {
      border-radius: 0;
    }
  }
  
  @media (min-width: 768px) {
    .about-card {
      border-radius: 10px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/About.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE;MACE,gBAAgB;IAClB;EACF;;EAEA;IACE;MACE,mBAAmB;IACrB;EACF","sourcesContent":[".about-container {\n    padding: 20px;\n  }\n  \n  .about-heading {\n    color: #333;\n  }\n  \n  .about-card {\n    margin-bottom: 20px;\n  }\n  \n  .highlighted-card {\n    background-color: #f8f8f8;\n    border: 2px solid #4285f4;\n    padding: 20px;\n  }\n  \n  @media (max-width: 768px) {\n    .about-card {\n      border-radius: 0;\n    }\n  }\n  \n  @media (min-width: 768px) {\n    .about-card {\n      border-radius: 10px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
