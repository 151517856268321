// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/CentraNo2-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/CentraNo2-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/CentraNo2-Book.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Centra;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  font-weight: 400;
}

* {
  font-family: 'Centra', sans-serif;
}

body {
  margin: 0;
  font-family: 'Centra', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,4CAAuC;EACvC,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,4CAAyC;EACzC,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,4CAAuC;EACvC,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,SAAS;EACT,iCAAiC;EACjC;;iBAEe;EACf,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb","sourcesContent":["@font-face {\n  font-family: Centra;\n  src: url('./assets/CentraNo2-Bold.ttf');\n  font-weight: 700;\n}\n\n@font-face {\n  font-family: Centra;\n  src: url('./assets/CentraNo2-Medium.ttf');\n  font-weight: 500;\n}\n\n@font-face {\n  font-family: Centra;\n  src: url('./assets/CentraNo2-Book.ttf');\n  font-weight: 400;\n}\n\n* {\n  font-family: 'Centra', sans-serif;\n}\n\nbody {\n  margin: 0;\n  font-family: 'Centra', sans-serif;\n  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif; */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
