import Footer from "../components/Footer";
import ClassTable from "./components/ClassTable";
import Helmet from 'react-helmet';

function App() {
  return (
<>

<Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-BXQHT68R6H"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-BXQHT68R6H');
            `}
          </script>
        </Helmet>
        
    <ClassTable />
    <Footer /></>
  );
}

export default App;
