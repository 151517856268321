import React from "react";
import Footer from './Footer.jsx'


const About = () => {
  return (
    <div>
    <h1 className="text-center my-4">Help</h1>
    <Footer />

    </div>
  )
};

export default About;
